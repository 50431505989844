interface MessageList {
  [name: string]: MessageList | string;
}
const messages: MessageList = {
  "login.page": {
    "get.started": "Sign In",
    "app.name": "TimePeace",
    "sign.in.below": "Enter your details below.",
    "email.placeholder": "Email",
    "password.placeholder": "Password",
    "log.in.button": "Sign In",
    "forgot.password": "Forgot your Password?",
    "user.not.found":
      "We do not recognize this e-mail or password. Check your information and try again.",
    "invalid.password":
      "We do not recognize this e-mail or password. Check your information and try again.",
    "invalid.email":
      "We do not recognize this e-mail or password. Check your information and try again.",
    "password.reset.ok": "The password has been updated successfully",
    "sign.up": "Sign Up Free",
    "dont.have.account": "Don't have an account?",
    "where.you.want.to.be": "The answer to where you want to be",
    "looking.for": "Looking for events on TimePeace?",
    "download.app": "Download the app",
    error:
      "We do not recognize this e-mail or password. Check your information and try again.",
    forgot: {
      progress: "You will recieve an email shortly",
      reset: "Reset",
      title: "Password Reset",
      subtitle: "Enter your account email",
      "user.not.found": "We don’t recognize this email. Please try again.",
      error: "There was an error with your request.2",
      success: "Thanks! Please check your inbox for a reset link."
    },
    verify: {
      title: "Please verify your email",
      description:
        "An email has been sent for you to verify your email in order to finish the sign up proccess."
    },
    "to.login": "Back"
  },
  "app.name": "TimePeace",
  "new.event.btn": "Create Event",
  general: {
    "all.day": "All Day",
    pdf: "PDF"
  },
  navigation: {
    calendar: "Calendar",
    manage: "Manage",
    logOut: "Log Out",
    profile: "Edit Profile",
    "create.organization": "New Organization",
    "requests.organization": "Requests",
    administrators: "Users",
    account: "Account",
    plugin: "Website Calendar",
    freeUsers: "Free Users",
    "view.cal": {
      month: "Month",
      week: "Week",
      schedule: "Schedule"
    }
  },
  manage: {
    title: "Manage Categories",
    "create.new": "Create a Category",
    "add.link": "Add Subcategory",
    "edit.link": "Edit"
  },
  "dialog.category": {
    title: {
      new: "Create Category",
      update: "Edit Category"
    },
    name: "Name",
    description: "Description",
    save: "Create",
    update: "Save",
    cancel: "Cancel",
    delete: "Delete",
    "confirm.delete":
      "Are you sure you want to delete {name} Category? All associated Subcategories and Events will also be deleted."
  },
  "dialog.calendar": {
    title: {
      new: "Create Subcategory",
      update: "Edit Subcategory"
    },

    name: "Name",
    description: "Description",
    save: "Create",
    update: "Save",
    cancel: "Cancel",
    delete: "Delete",
    "confirm.delete":
      "Are you sure you want to delete {name} Subcategory? All associated Events will also be deleted."
  },
  "dialog.profile": {
    title: "Edit Profile",
    name: "Organization Name",
    type: "Organization Type",
    address: "Organization Address",
    address2: "Organization Address 2",
    phone: "Organization Phone",
    fax: "Organization Fax",
    email: "Organization Email",
    website: "Organization Website",
    banner: "Organization Banner",
    icon: "Organization Icon",
    city: "City",
    state: "State",
    zip: "Zip",
    "request.title": "Organization Details",
    "search.addres": "Address",
    country: "Country",
    "field.description": "Organization Description",
    "field.info": "More information",
    description: "Add Organization Description",
    "upload.banner": "Upload Banner",
    "upload.icon": "Upload Icon",
    "upload.notes.1":
      "For the icon, upload a square jpg or png file with minimum size of 512 x 512 pixels",
    "upload.notes.2":
      "For the bannner, upload a rectangle jpg or png file with minimum of 1650 x 675  pixels",
    "upload.failed":
      "This image format and/or file size does not work. Please use the recommended file format and size for your images.",
    additionalInformation: "Additional Organization Information",
    "contact.name": "Organization Contact {name} Name",
    "contact.email": "Organization Contact {name} Email",
    "contact.title": "Organization Contact {name} Title",
    "contact.phone": "Organization Contact {name} Phone",
    "add.contact": "Add a contact",
    "remove.contact": "Delete",
    delete: "Remove",
    facebook: "Facebook",
    instagram: "Instagram",
    pinterests: "Pinterest",
    linkedin: "LinkedIn",
    youtube: "YouTube",
    twitter: "Twitter",
    cancel: "Cancel",
    save: "Save",
    new: {
      title: "Create organization"
    },
    "upload.error": "There was an unexpected error while uploading the files",
    "no.edit.msg":
      "To update the Organization Name, Address, Type, please contact:",
    "mail.edit.contact": "hello@timepeace.io",
    confirm: {
      delete: "Do you really want to delete {orgName}?"
    }
  },
  "dialog.events": {
    new: {
      title: "Create New Event"
    },
    update: {
      title: "Update Event"
    },
    confirm: {
      calendar: "We will create a new subcategory, you sure?",
      discard: "Discard unsaved changes?",
      "delete.recurring": {
        all: "Delete All Occurrences",
        future: "Delete Future Occurrences",
        thisevent: "Delete Only This Event"
      },
      recurring:
        "By unselecting recurring, other dates associated with this series will be deleted. Do you want to continue?"
    },
    button: {
      timezone: "Time zone"
    },
    timezone: "Event Time Zone",
    select: {
      calendar: "Select a Subcategory",
      category: "Select a Category",
      timezone: "(auto)",
      title: "Enter an event title",
      address: "Enter a location",
      "location.description": "Enter Room, Field, etc.",
      description: "Enter event description",
      "online.description": "Enter event description including online meeting access information",
      "online.description.error": "Please enter event description including online meeting access information"
    },
    "all.day": "All Day",
    push: "Send a push notification",
    save: "Save",
    "on.day": "on day ",
    "cannot.edit.event":
      "Once an event is created the recursion nature cannot be updated",
    recurring: {
      title: "This is a recurring event",
      ends: "Ends",
      repeats: {
        every: "Repeats Every",
        on: "Repeat on"
      }
    },
    "is.online":"This event is online",
    validation: {
      calendar: "Select a Subcategory for your event",
      category: "Please select a Category for your event",
      title: "Please add a title for the event",
      address: "Please enter a valid location",
      "error.title": "We are missing some key data for your event",
      endTime: "The end date and time must occur after start date and time."
    },
    "confirm.delete": "Are you sure you want to delete this event?"
  },
  to: "to",
  yes: "Yes",
  no: "No",
  ok: "Ok",
  save: "Save",
  discard: "Discard",
  cancel: "Cancel",
  "save.changes": "Save Changes",
  delete: "Delete",
  continue: "Continue",
  close: "Close",
  occurrences: "occurrences",
  days: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday"
  },
  calendar: {
    view: {
      monthly: "Month",
      weekly: "Week",
      schedule: "Schedule"
    }
  },
  appInfo: {
    "version.line": "TimePeace version 3.0.1",
    feedback: "Send us feedback",
    "feedback.link": "hello@timepeae.io",
    "terms.conditions": "Terms of Use",
    "link.terms": "https://timepeace.io/terms",
    policy: "Privacy Policy",
    "policy.link": "https://timepeace.io/privacy"
  },
  "user.admin": {
    "create.title": "Create New User",
    "details.title": "User details",
    title: "Manage Users",
    fields: {
      email: "E-Mail",
      firstName: "First Name",
      lastName: "Last Name",
      password: "Password",
      confirmation: "Password Confirmation"
    },
    button: {
      "add.user": "Send Login",
      search: "SEARCH",
      add: "Add User",
      remove: "Delete user"
    },
    "user.not.found":
      "A user with that email address was not found. Create a new user to add that person to this organization.",
    status: {
      success: "Success",
      "user.exists":
        "This user is already registered. Search Users to add the user to this organization.",
      general: "There was an error with your request"
    }
  },
  notifications: {
    added: "{event_name} is {start_date}.",
    updated: "Updated {event_name} on {start_date}.",
    updatedStartTime: "{event_name} time changed to {start_time}.",
    updatedStartDate: "{event_name} date changed to {start_date}.",
    locationChanged: "{event_name} venue changed to {venue}.",
    updatedDetails: "{event_name} has updated event details.",
    updatedRecurrence: "{event_name} has updated the event recurrence.",
    generalUpdate: "Updated {event_name} on {start_date}.",
    deleted: "{event_name} is cancelled for {date}.",
    addTitle: "{organization_name} has a new event",
    updateTitle: "{organization_name} event update",
    deleteTitle: "{organization_name} has an event cancellation",
    reminderTitle: "{organization_name} event reminder",
    reminderText: "{event_name} is {full_date}."
  },
  "org.requests": {
    name: "Name",
    type: "Type",
    description: "Description",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
    reject: "Reject",
    approve: "Approve"
  },
  signup: {
    error: {
      organization: "Please type the organization name",
      address: "Please type an address for the organization",
      firstname: "The Firstname of the Admin user is required",
      lastname: "The Lastname of the Admin user is required",
      email: "Please type an email for the Admin user",
      emailsDontMatch: "Emails don't match",
      password: "Please type an email for your password",
      confirmation: "Passwords do not match",
      "password.not.match.requirements":
        "Passwords should be at least 10 characters long, have at least one upper case character, one lower case character, one digit and special sign (#@^!$()*+_,.{}?-)"
    },
    "agree.with.terms": "I agree with TimePeace's  ",
    terms: "Terms of Use",
    and: "and",
    privacy: "Privacy Policy",
    "admin.name": "Admin First Name",
    "admin.last.name": "Admin Last Name",
    "email.confirmation": "Confirm Administrator Email",
    "email.invalid": "Please enter a valid email address",
    "cannot.be.changed": "* This Cannot Be Changed",
    save: "Continue",
    continueMessage:
      "Thanks for signing up. We’ll occasionally send you account related emails.",
    title: "Sign Up your Organization",
    subtitle: "Start sharing your events via TimePeace today.",
    organization: {
      name: "Organization Name",
      address: "Organization Address"
    },
    email: "Administrator Email",
    password: "Create Password",
    confirmation: "Confirm Password",
    footNote:
      "Get the TimePeace app to see what's happening near you.  Download in the Apple App Store or Google Play Store.",
    footNoteLink: "Download the app.",
    tc: {
      description: "By signing up you agree to the ",
      terms: "terms of use",
      and: " and ",
      privacy: "privacy policy"
    },
    success: {
      title: "Thank you for signing up",
      description:
        "We'll review  your registration information and validate your account. Please check your inbox for a welcome email with  TimePeace log-in instructions within 48 hours."
    },
    almostThere: {
      title: "You're Almost There",
      description:
        "An email was sent to {address} with a verification link. Please check your email to continue signup.",
      "if.havent.recievied": "If you didn’t receive it, ",
      "check.reasons": "check out these potential reasons to troubleshoot:",
      "reasons.description":
        "If you haven't received an email in a few minutes after signing up, these things may have happened: ",
      "reason.1": "The email is in your spam folder. ",
      "reason.2": "The email address you entered had a mistake. ",
      "reason.3": "The email entered was a different email address. ",
      "reason.4":
        "We can’t deliver the email to this address because of corporate firewalls or filtering. "
    },
    existingOrgNameZip:
      "An organization with that name and location already exists, please update organization name. Perhaps include a street name or other distinguishing name. If you believe that someone who is unauthorized to represent your organization has set up an account, please email: hello@timepeace.io. Be very specific so that we can better help you.",
    existingOrgNameAddress:
      "An organization with that name and location already exists. If you believe that someone who is unauthorized to represent your organization has set up an account, please email: hello@timepeace.io. Be very specific so that we can better help you.",
    existingEmail:
      "That email address is already registered. If you have forgotten your password, go to Login (www.timepeace.io/login) and click Forgot password. If you are signing up a new organization, please use another email address.",
    multi: {
      success: {
        title: "Welcome {orgName}!",
        description1:
          "This organization was signed up under an existing account.",
        description2: "Use your current account information to",
        description3:
          "to manage this organization. Complete the Profile to make this organization visible on the TimePeace app."
      },
      popup: {
        error: {
          description1:
            "Incorrect password. That email address is already registered. If you have forgotten your password, go to",
          description2: "and click Forgot password."
        },
        cancel: "Use a different email address",
        continue: "Continue",
        ok: "ok",
        yes: "yes",
        passwordPh: "Password",
        passwordLabel: "Please enter the current password",
        title: "Existing Email Address Found",
        descriptionPortal1:
          "That email address is already registered for TimePeace. If you have forgotten your password, go to",
        descriptionPortal2: "and click Forgot password.",
        descriptionPortal3:
          "If you are signing up a new organization, either use a different email address or login with your existing credentials and add a new organization within your account.",
        descriptionMobile1:
          "That email address is already registered for the TimePeace App.",
        descriptionMobile2: "If you are signing up a new organization, please use a different email address to sign up for the TimePeace portal.",
      }
    }
  },
  account: {
    title: "Account",
    profile: {
      tab: "Account",
      "first.name": "Administrator Name",
      "last.name": "Administrator Last Name",
      email: "Administrator Email",
      "change.password": "Change password",
      "edit.profile": "Edit my Account",
      "edit.first.error": "Firstname is required",
      "edit.last.error": "Lastname is required",
      "edit.email.error": "Please enter a valid email address"
    },
    organization: {
      profile: {
        "edit.name.error": "Organization name is required",
        "edit.address.error": "Please enter an address"
      }
    },
    plan: {
      tab: "Active Plan",
      "your.plan": "Your Plan",
      upgrade: "Upgrade Plan",
      "cancel.plan": "Cancel Plan",
      "billing.info": "Billing Info",
      "update.billing.info": "Update info",
      "show.invoices": "Show all invoices",
      "invoice.history": "Invoice History",
      "billed.annually": "(${amount} billed annually)",
      "billed.monthly": "(billed monthly)",
      "per.mont": "/ month"
    }
  },
  plans: {
    free: "Free",
    full: "Full",
    premium: "Premium",
    change: {
      upgrade: "Upgrade Plan",
      downgrade: "Downgrade Plan",
      change: "Change Plan"
    },
    features: {
      1: "Unlimited Events",
      2: "Recurring Events",
      3: "Categories to Filter",
      4: "Feeds Mobile App",
      5: "App Reminders",
      6: "1 Account User",
      7: "All Free features plus:",
      8: "Feed Website Calendar",
      9: "App Notifications",
      10: "Multiple Account Users",
      11: "Priority Support"
    }
  },
  payments: {
    "page.sub.header": "The answer to where you want to be",
    plan: {
      free: {
        title: "Choose your plan",
        subtitle: "Engage your audiences even better with our premium options."
      },
      paid: {
        title: "Considering canceling your paid subscription?",
        subtitle: "Review the plan options below before you make your decision."
      },
      current: "Current Plan",
      continue: "Continue with {plan} Plan",
      choosePlanBtn: "Choose {plan} Plan",
      features: {
        1: "Feature 1",
        2: "Feature 2",
        3: "Feature 3",
        4: "Feature 4",
        5: "Feature 5"
      }
    },
    paymentDetails: {
      useSameBillingAddress: "Use the same billing address",
      address: "Address",
      city: "City",
      state: "State",
      zip: "Zip",
      cardNumber: "Card number",
      expiration: "Expiration",
      cvc: "Security Code",
      payBtn: "Pay ${amount}",
      updateBillingInfo: "Update your payment information",
      title: "Enter your payment method",
      paymentInfoUpdateTitle: "Update your payment method",
      subtitleText1: "By saving a credit card, you agree to our ",
      subtitleText2:
        ", and by using it to pay for a subscription or plan, you authorize your credit card to be charged on a recurring basis until you cancel, which you can do at any time.",
      footerText:
        "By clicking the button below, I authorize TimePeace to charge my account for the amount of ${amount} on {chargeDate}, then recurring on a monthly basis.",
      addressError: "Please enter a valid address",
      cityStateZipError: "City, State and Zip are required",
      cardNumberError: "A valid card number is required",
      cardExpCvcError:
        "A valid card expiration date and security code are required",
      nameOnCard: "Name on Card",
      nameOnCardError: "Name on card is required",
      payBtnProcessing: "Processing"
    }
  },
  tips: {
    title: "Getting started",
    subtitle: "To start sharing your events:",
    list: {
      1: "On the Manage page, set up your categories and subcategories. This is how your users will find and subscribe to your events.",
      2: "On the Calendar page, enter your events.",
      3: "On the Edit Profile page (accessible from the Manage page), enter data about your organization including images and contact information.",
      4: "Upon saving your profile, your organization will be live in the TimePeace mobile app.",
      5: "Add the code from the Website Calendar page to your website to begin promoting your events to your website users."
    },
    footer: `Find more TimePeace tips and tutorials.`
  }
};

const flatternMessages = (
  msgs: MessageList,
  baseKey: string = "",
  allMessages?: { [name: string]: string }
): { [name: string]: string } => {
  if (!allMessages) {
    const flatternedMsgs: { [name: string]: string } = {};
    flatternMessages(msgs, baseKey, flatternedMsgs);
    return flatternedMsgs;
  } else {
    Object.keys(msgs).forEach(k => {
      const messageResource = msgs[k];
      const composedKey = `${baseKey}${baseKey ? "." : ""}${k}`;
      if (typeof messageResource === "string") {
        allMessages[composedKey] = messageResource;
      } else {
        flatternMessages(messageResource, composedKey, allMessages);
      }
    });
  }
};

export default flatternMessages(messages);
