import Organization from "../../models/organization";
import { getOrganizationPlan } from "../../models/plan";

export function getOrganizationAddressText(org: Organization) {
  // return org
  //   ? `${org.address || ""} ${org.address2 || ""}, ${org.zip ||
  //       ""}, ${org.city || ""} ,${org.state || ""}`
  //   : "";
  let orgAAddressDisplay = org.address || "";
  orgAAddressDisplay += orgAAddressDisplay.length > 0 ? ", " : "";
  orgAAddressDisplay += org.city || "";
  orgAAddressDisplay += orgAAddressDisplay.length > 0 ? ", " : "";
  orgAAddressDisplay += org.state || "";
  orgAAddressDisplay += orgAAddressDisplay.length > 0 ? " " : "";
  orgAAddressDisplay += org.zip || "";
  orgAAddressDisplay += orgAAddressDisplay.length > 0 ? ", " : "";
  orgAAddressDisplay += org.country || "";
  let finalOrgAddressDisplay = orgAAddressDisplay
    .replace(", ,", ", ")
    .replace(",  ", "")
    .replace("  ,", "")
    .trim();
  return finalOrgAddressDisplay.startsWith(",")
    ? finalOrgAddressDisplay.substring(1)
    : finalOrgAddressDisplay;
}

export function isOrganizationPaidUser(org: Organization) {
  return (org && org.freeUser) || getOrganizationPlan(org).pricePerMonth > 0;
}

export function isOrganizationPaidUserForPns(org: Organization) {
  return org && getOrganizationPlan(org).pricePerMonth > 0;
}
