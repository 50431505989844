import * as React from "react";
import { connect } from "react-redux";
import { AppState, CalendarDatesView } from "../../state/state";
import VerticalNavBar from "./vertical.nav.bar";
import { LocaleProps, localizable } from "../i18n/localizable";
import { logOut } from "../../actions/auth.actions";
import { Route, Switch } from "react-router";
import { Location } from "history";
import asyncComponent from "../util/async.component";
import Button from "../input/button";
import NewEventDialog from "../pages/new-event/index";
import ManagePage from "../pages/manage/index";
import "./layout.less";
import OrganizationRequestsPage from "../pages/org-requests/index";
import Organization from "../../models/organization";
import { push, goBack } from "connected-react-router";
import NewCategoryDialog from "../pages/new-category-dialog";
import CalendarEditDialog from "../pages/calendar-edit-dialog/calendar.edit.dialog";
import ProfileEditDialog from "../pages/profile-edit/profile.edit.dialog";
import { changeCalendarDatesView, updateWidth } from "../../actions/ui.actions";
import { Motion, spring } from "react-motion";
import UserAdmin from "../pages/user-admin/user.admin";
import { User } from "../../models/User";
import { getSelectedOrganization } from "../../state/state_getters";
import MultiSelect from "../input/multi.select";
import { selectOrganization } from "../../actions/organization.actions";
import AccountLayout from "../pages/account/account.layout";
import PaymentInfo from "../pages/payments/payment.info";
import OrganizationInfoHolder from "./organization.info.menu";
import PluginExport from "../pages/plugin/plugin.export";
import { Services } from "../../api/services";
import { isEmpty } from "../../utils/string.utils";
import { isOrganizationPaidUser } from "../util/organization.utils";
import { FreeUsers } from "../pages/free-users";
const pathLogo = require("./images/logo.png");

interface PageProps {
  children: any;
  currentLocation: Location;
  organization: Organization;
  organizations: Organization[];
  logOut: () => any;
  openModal: (path: string) => any;
  open: (path: string) => any;
  closeModal: () => any;
  switchView: (view: CalendarDatesView, redirect?: boolean) => any;
  daysView: CalendarDatesView;
  user: User;
  updateWidth: (width: number) => any;
  selectOrganization: (org: Organization) => any;
  isPaidOrga?: boolean;
  showMenu: boolean;
}
interface PageState {
  showMenu: boolean;
  calendarDatesView?: string;
}
const VIEW_CAL_OPTIONS = [
  CalendarDatesView.Monthly,
  CalendarDatesView.Weekly,
  CalendarDatesView.Schedule
];

class MainPageLayout extends React.PureComponent<
  PageProps & LocaleProps,
  PageState
> {
  previousLocation: Location;
  constructor(props: PageProps & LocaleProps) {
    super(props);
    this.state = {
      showMenu: false,
      calendarDatesView: props.user.settings
        ? props.user.settings.defaultDaysView
          ? props.user.settings.defaultDaysView
          : "monthly"
        : "monthly"
    };
  }
  onWidthChange = (e: UIEvent) => {
    const width = window.innerWidth;
    if (this.state.showMenu && window.innerWidth > 750) {
      this.setState({ showMenu: false });
    }
    this.props.updateWidth(width);
  };
  componentDidMount() {
    window.addEventListener("resize", this.onWidthChange);
    this.props.updateWidth(window.innerWidth);
    if (
      this.props.user.settings &&
      this.props.user.settings.defaultDaysView !== this.props.daysView &&
      !isEmpty(this.props.user.settings.defaultDaysView)
    ) {
      this.props.switchView(
        this.props.user.settings.defaultDaysView as CalendarDatesView,
        true
      );
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onWidthChange);
  }
  componentWillUpdate(nextProps: PageProps & LocaleProps) {
    const { currentLocation, user, organization, organizations } = this.props;
    //nextProps.history.action !== 'POP' &&
    // set previousLocation if props.location is not modal
    if (!currentLocation.state || !currentLocation.state.modal) {
      this.previousLocation = this.props.currentLocation;
    }

    if (
      user.settings &&
      organization &&
      user.settings.defaultOrganizationId !== organization.id
    ) {
      let org = organizations.find(
        o => o.id === user.settings.defaultOrganizationId
      );
      if (org !== undefined) {
        this.props.selectOrganization(org);
      }
    }

    if (this.state.showMenu != nextProps.showMenu) {
      this.setState({
        ...this.state,
        showMenu: nextProps.showMenu
      });
    }
  }
  hideMenu = () => {
    this.setState({ showMenu: false });
  };
  handleViewChange = (option: string) => {
    this.setState({ ...this.state, calendarDatesView: option });
    this.props.switchView(option as CalendarDatesView, true);
  };
  render() {
    const { children, currentLocation, _ } = this.props;
    const isModal =
      !!(
        currentLocation.state &&
        currentLocation.state.modal &&
        (!this.previousLocation ||
          this.previousLocation.pathname !== currentLocation.pathname)
      ) && true; // not initial render
    const inIndex = !currentLocation || currentLocation.pathname == "/";
    let location = isModal ? this.previousLocation : currentLocation;
    if (!location) {
      location = {
        pathname: "/",
        search: "",
        hash: "",
        state: null
      };
    }
    let selected = this.props.user
      ? this.props.user.settings
        ? this.props.user.settings.defaultDaysView
          ? (this.props.user.settings.defaultDaysView as CalendarDatesView)
          : this.props.daysView
        : this.props.daysView
      : this.props.daysView;

    const defaultOption =
      VIEW_CAL_OPTIONS[
        (VIEW_CAL_OPTIONS.indexOf(this.state
          .calendarDatesView as CalendarDatesView) +
          1) %
          VIEW_CAL_OPTIONS.length
      ];

    return (
      <div className="layout">
        <div
          className={`layout_left_bar__app layout_left_bar__app--${
            this.state.showMenu ? "opened" : "closed"
          }`}
        >
          <div className="layout_left_bar__app__name">
            <img src={pathLogo} />
          </div>
        </div>

        <Motion
          defaultStyle={{ opacity: 0 }}
          style={{ opacity: spring(this.state.showMenu ? 1 : 0) }}
        >
          {newStyle => (
            <div
              className="layout__menu_backdrop"
              style={{
                opacity: newStyle.opacity,
                display: newStyle.opacity == 0 ? "none" : "block"
              }}
              onClick={() => this.setState({ showMenu: false })}
            />
          )}
        </Motion>

        <div
          className={`layout_left_nav_bar__nav_container layout_left_nav_bar__nav_container--${
            this.state.showMenu ? "opened" : "closed"
          }`}
        >
          <OrganizationInfoHolder
            organization={this.props.organization}
            user={this.props.user}
          />

          <VerticalNavBar
            currentLocation={location.pathname}
            onRedirect={() => this.setState({ showMenu: false })}
            items={[
              {
                paths: [""],
                onClick: () => {
                  this.props.switchView(CalendarDatesView.Monthly, !inIndex);
                  this.hideMenu();
                },
                message: "navigation.view.cal.month",
                iconName: "calendar",
                small: true
              },
              {
                paths: [""],
                onClick: () => {
                  this.props.switchView(CalendarDatesView.Weekly, !inIndex);
                  this.hideMenu();
                },
                message: "navigation.view.cal.week",
                iconName: "calendar",
                small: true
              },
              {
                paths: [],
                onClick: () => {
                  this.props.switchView(CalendarDatesView.Schedule, !inIndex);
                  this.hideMenu();
                },
                message: "navigation.view.cal.schedule",
                iconName: "calendar",
                small: true
              },
              {
                paths: ["/"],
                message: "navigation.calendar",
                redirect: "/",
                iconName: "calendar",
                big: true
              },
              {
                paths: ["/manage"],
                message: "navigation.manage",
                redirect: "/manage",
                iconName: "manage"
              },
              {
                paths: ["/organization/edit"],
                message: "navigation.profile",
                redirect: "/organization/edit",
                iconName: "manage",
                small: true
              },
              {
                paths: ["/organization/create"],
                message: "navigation.create.organization",
                redirect: "/organization/create",
                iconName: "plus",
                superUser: true
              },
              /*
              {
                paths: ["/organization/requests"],
                message: "navigation.requests.organization",
                redirect: "/organization/requests",
                iconName: "manage",
                superUser: true
              },*/
              {
                paths: ["/organization/admins"],
                message: "navigation.administrators",
                redirect: "/organization/admins",
                iconName: "users",
                visible: this.props.isPaidOrga,
                superUser: true,
                creator: true
              },
              {
                paths: ["/account/profile"],
                message: "navigation.account",
                redirect: "/account/profile",
                iconName: "gear",

                superUser: true,
                creator: true
              },
              {
                paths: ["/plugin/pluginexport"],
                message: "navigation.plugin",
                redirect: "/plugin/pluginexport",
                iconName: "plugin"
                //superUser: true,
                //creator: true,
                //visible: this.props.isPaidOrga
              },
              {
                paths: ["/organization/free-users"],
                message: "navigation.freeUsers",
                redirect: "/organization/free-users",
                iconName: "gear",
                superUser: true
              },
              {
                paths: [],
                message: "navigation.logOut",
                onClick: () => {
                  this.props.logOut();
                },
                iconName: "out"
              }
            ]}
            _={_}
            user={this.props.user}
            selectedOrganization={this.props.organization}
          />

          <div className="vertical_nav_bar__app_info">
            <div className="vertical_nav_bar__app_info__line">
              {_("appInfo.version.line")}
            </div>
            <div className="vertical_nav_bar__app_info__line">
              <a href={`mailto:${_("appInfo.feedback.link")}`}>
                {_("appInfo.feedback")}
              </a>
            </div>
            <div className="vertical_nav_bar__app_info__line">
              <a target="_blank" href={_("appInfo.link.terms")}>
                {_("appInfo.terms.conditions")}
              </a>
              <br />
            </div>
            <div className="vertical_nav_bar__app_info__line">
              <a target="_blank" href={_("appInfo.policy.link")}>
                {_("appInfo.policy")}
              </a>
            </div>
            <div>
              <div />
              <div className="vertical_nav_bar__app_info__line">
                &copy; 2019
              </div>
            </div>
          </div>
        </div>

        <div className="layout_right_panel__title_bar">
          <div className="layout_right_panel__title_bar__hamburger_conainter">
            <div
              className="layout_right_panel__title_bar__hamburger"
              onClick={() => this.setState({ showMenu: !this.state.showMenu })}
            />
          </div>
          <div className="layout_right_panel__title_bar__title">
            <MultiSelect
              items={this.props.organizations}
              selectedItems={
                this.props.organization
                  ? { [this.props.organization.id]: this.props.organization }
                  : {}
              }
              getKey={(o: Organization) => (o ? o.id || "" : "")}
              getText={(o: Organization) => (o ? o.name || "" : "")}
              onChange={(items: Organization[]) => {
                this.props.selectOrganization(items[0]);
              }}
              showSelectedAsText={true}
              additionalClass="layout_right_panel__title_bar__select_organization multiselect--header"
              oneOption={true}
            >
              {this.props.organization ? this.props.organization.name : ""}
            </MultiSelect>
          </div>
          <div className="layout_right_panel__title_bar__button_container">
            <Switch>
              <Route path="/manage" exact>
                <Button
                  text={_("navigation.profile")}
                  onClick={() => this.props.open("/organization/edit")}
                />
              </Route>
              <Route path="/organization/admins" exact>
                <Button
                  text={_("navigation.profile")}
                  onClick={() => this.props.open("/organization/edit")}
                />
              </Route>
              <Route path="/" exact>
                <Button
                  text={_(`calendar.view.${this.state.calendarDatesView}`)}
                  onClick={(option: string) => {
                    this.handleViewChange(option);
                  }}
                  options={VIEW_CAL_OPTIONS.map(o => ({
                    text: _(`calendar.view.${o}`),
                    key: o
                  }))}
                  defaultOption={defaultOption}
                />
              </Route>
            </Switch>
          </div>
          <div className="layout_right_panel__title_bar__right_separator" />
        </div>
        <div className="layout_right_panel__content">
          <Route
            exact
            path={"/"}
            component={asyncComponent(
              () => import("../pages/calendar/index"),
              "CalendarPage"
            )}
          />
          <Route
            path={"/events/"}
            component={asyncComponent(
              () => import("../pages/calendar/index"),
              "CalendarPage"
            )}
          />
          <Route
            path={"/(manage|categories|calendar)/"}
            render={() => <ManagePage key="manage-page" />}
          />
          <Route
            exact
            path="/organization/create"
            render={() => <ProfileEditDialog isNew={true} />}
          />
          <Route
            exact
            path="/organization/edit"
            render={() => (
              <ProfileEditDialog
                key={`profile-organization-${
                  this.props.organization ? this.props.organization.id : -1
                }`}
              />
            )}
          />
          <Route exact path="/organization/admins" component={UserAdmin} />
          <Route path="/account/" component={AccountLayout} />

          <Route
            exact
            path="/organization/requests"
            component={OrganizationRequestsPage}
          />

          <Route exact path="/plugin/pluginexport" component={PluginExport} />
          <Route path="/organization/free-users" component={FreeUsers} exact />
        </div>
        <Route path="/events/new" component={NewEventDialog} exact />
        <Route
          path="/events/edit/:key/:date"
          component={NewEventDialog}
          exact
        />
        <Route path="/categories/new" component={NewCategoryDialog} exact />
        <Route
          path="/categories/edit/:key"
          component={NewCategoryDialog}
          exact
        />
        <Route
          path="/calendar/edit/:categoryId/:key"
          component={CalendarEditDialog}
          exact
        />
        <Route
          path="/calendar/:categoryId/new"
          component={CalendarEditDialog}
          exact
        />
      </div>
    );
  }
}
export default connect(
  (state: AppState) => ({
    currentLocation: state.router.location,
    organization: getSelectedOrganization(state),
    organizations: state.organization.organizations,
    daysView: state.ui.calendarDatesView,
    user: state.currentUser.user,
    isPaidOrga: isOrganizationPaidUser(getSelectedOrganization(state)),
    showMenu: state.ui.showMenu
  }),
  dispatch => ({
    logOut() {
      dispatch(logOut());
    },
    openModal(path: string) {
      dispatch(push(path, { modal: true }));
    },

    open(path: string) {
      dispatch(push(path));
    },
    switchView(view: CalendarDatesView, redirect?: boolean) {
      this.updateUserSettings(this.user, {
        name: "defaultDaysView",
        value: view
      });
      dispatch(changeCalendarDatesView(view));
      if (redirect) {
        dispatch(push("/"));
      }
    },
    updateWidth(width: number) {
      dispatch(updateWidth(width));
    },
    selectOrganization(organization: Organization) {
      this.user.settings.defaultOrganizationId = organization.id;
      this.updateUserSettings(this.user, {
        name: "defaultOrganizationId",
        value: organization.id
      });
      dispatch(selectOrganization(organization.id));
    },
    updateUserSettings(user: User, setting: any) {
      Services.Users.updateUserSettings(user, setting);
    }
  })
)(localizable(MainPageLayout));
